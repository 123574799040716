






































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/FAQController'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import StarIcon from '@/app/ui/assets/star_icon.vue'
import AddCirlceIcon from '@/app/ui/assets/add_circle.vue'
import { Utils } from '@/app/infrastructures/misc'
import dayjs from 'dayjs'
import { FAQ_PAGINATION } from '@/app/infrastructures/misc/Constants'

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    Loading,
    ExpandIcon,
    EmptyState,
    DataTable,
    StarIcon,
    AddCirlceIcon
  },
})
export default class FAQPage extends Vue {
  controller = controller
  onLoadingCategoryId = 0
  isGuest = Utils.isGuest()
  searchMode = false
  statusOptions = [
    { label: 'Show All', value: '' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' },
  ]
  parameters = {
    page: 1,
    perPage: FAQ_PAGINATION,
    search: null,
    filterStatus: this.statusOptions[0],
  }
  headers = [
    'FAQ ID',
    'FAQ Category Name',
    'Questions',
    'Status',
    'Last Modified Date',
  ]
  faqDataTable = controller.faqData.map((faq) => [
    faq.id,
    faq.categoryName,
    faq.questionsCount,
    faq.isActive ? 'Active' : 'Inactive',
    this.formatDate(faq.updatedAt),
  ])

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      if (queries.search) {
        this.searchMode = true
      }

      this.parameters.search = queries.search
      this.parameters.filterStatus =
        this.statusOptions.find(
          (item) => queries.filterStatus === item.value
        ) || this.statusOptions[0]
    }

    this.fetchFAQData()
  }

  get params() {
    if (!this.parameters.search) {
      this.searchMode = false
    }
    return {
      ...this.parameters,
      filterStatus: this.parameters.filterStatus.value || '',
    }
  }

  get isFullyLoaded(): boolean {
    return (
      controller.faqData.length >= (controller.paginationData.totalItem || 0)
    )
  }

  @Watch('parameters.search')
  onKeywordChanged(val: string): void {
    val === '' && this.fetchFAQData(true)
  }

  @Watch('params')
  onParamsChanged(val: Record<string, never>): void {
    this.$router.replace({
      query: { search: val.search, filterStatus: val.filterStatus },
    })
  }

  @Watch('controller.faqData')
  onFaqDataChanged(): void {
    this.faqDataTable = controller.faqData.map((faq) => [
      faq.id,
      faq.categoryName,
      faq.questionsCount,
      faq.isActive ? 'Active' : 'Inactive',
      this.formatDate(faq.updatedAt),
    ])
  }

  private fetchFAQData(resetPage = false, isAppend = false) {
    if (resetPage) {
      this.parameters.page = 1
    }
    controller.getFAQList({ params: this.params, isAppend: isAppend })
  }

  private onLoadMore() {
    this.parameters.page = this.parameters.page + 1
    this.fetchFAQData(false, true)
  }

  private fetchFAQDetail(categoryId: string) {
    this.onLoadingCategoryId = Number(categoryId)
    controller.addFAQDetailToList(categoryId)
  }

  private onDropped($event: { moved: any }) {
    controller.updateFAQCategoryOrder({
      categoryId: $event.moved.element[0],
      toOrderNumber: $event.moved.newIndex + 1,
    })
  }

  private onDropUpdate(value: any) {
    this.faqDataTable = value
  }

  private onEnterSearch() {
    if (this.parameters.search) {
      this.searchMode = true
    }
    this.fetchFAQData(true)
  }

  private formatDate(date: string | undefined) {
    return date
      ? dayjs(date).format('DD MMMM YYYY, HH:mm [WIB]')
      : '-'
  }
}
